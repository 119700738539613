/* CSS to get text to apear over image on hover */
.postsgrid {
  object-fit: cover;
  width: 100%;
  height: 100%;
  vertical-align: bottom;
  -webkit-user-select: none; /* Safari */        
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */

}

.img__wrap {
    position: relative;
    display: inline-block;
    height: 100%;
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
    -webkit-user-select:none;
    -webkit-touch-callout:none;
  
  }

  .img__default_layer {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    color: #fff;
    visibility: visible;
    opacity: 1;
    overflow: auto;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 70%, rgba(0, 0, 0, 1) 100%);
    border-radius: 10px;
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
  
  }

  .img__default_layer_mobile {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    color: #fff;
    visibility: visible;
    opacity: 1;
    overflow: auto;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 70%, rgba(0, 0, 0, 1) 100%);
    border-radius: 10px;
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
  
  }


  .img__wrap:hover .img__default_layer {
    visibility: hidden;
    opacity: 0;
  }

  .img__default {
    transform: translateY(0);
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
  
  }

  .img__default_mobile {
    transform: translateY(0);
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
  
  }
  
  .img__wrap:hover .img__default {
    transition: .2s;
    transform: translateY(1em);
  }

  .img__description_layer_mobile {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 10px;
    background: linear-gradient(180deg, rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%);
    color: #fff;
    visibility: visible;
    opacity: 1;
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
    width: 100vw;

  
    /* transition effect. not necessary */
    transition: opacity .2s, visibility .2s;
  }

  .img__description_layer {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 10px;
    background: linear-gradient(180deg, rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%);
    color: #fff;
    visibility: hidden;
    opacity: 0;
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
  
    /* transition effect. not necessary */
    transition: opacity .2s, visibility .2s;
  }
  
  .img__wrap:hover .img__description_layer {
    visibility: visible;
    opacity: 1;
  }
  
  .img__description {
    transition: .2s;
    transform: translateY(1em);
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
  
  }
  
  .img__wrap:hover .img__description {
    transform: translateY(0);
  }

  .img__description_mobile {
    transition: .2s;
    transform: translateY(1em);
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
    margin-bottom: 5vh;
  
  }