.react-daterange-picker {
    display: inline-flex;
    position: relative;
    background-color: var(--gray-2);
    height: 32px;
  }
  
  .react-daterange-picker,
  .react-daterange-picker *,
  .react-daterange-picker *:before,
  .react-daterange-picker *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  
  .react-daterange-picker--disabled {
    background-color: var(--accent-3);
    color: var(--accent-7);
  }
  
  .react-daterange-picker__wrapper {
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    align-items: center;
    border: thin solid var(--gray-4);
    border-radius: 6px;
  }
  
  .react-daterange-picker__inputGroup {
    min-width: calc((4px * 3) + 0.54em * 8 + 0.217em * 2);
    height: 100%;
    flex-grow: 1;
    padding: 0 2px;
    box-sizing: content-box;
  }
  
  .react-daterange-picker__inputGroup__divider {
    padding: 1px 0;
    white-space: pre;
  }
  
  .react-daterange-picker__inputGroup__divider,
  .react-daterange-picker__inputGroup__leadingZero {
    display: inline-block;
    font: inherit;
  }
  
  .react-daterange-picker__inputGroup__input {
    min-width: 0.54em;
    height: 100%;
    position: relative;
    padding: 0 1px;
    border: 0;
    background: none;
    color: currentColor;
    font: inherit;
    box-sizing: content-box;
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;
  }
  
  .react-daterange-picker__inputGroup__input::-webkit-outer-spin-button,
  .react-daterange-picker__inputGroup__input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }
  
  .react-daterange-picker__inputGroup__input:invalid {
    background: rgba(255, 0, 0, 0.1);
  }
  
  .react-daterange-picker__inputGroup__input--hasLeadingZero {
    margin-left: -0.54em;
    padding-left: calc(1px + 0.54em);
  }
  
  .react-daterange-picker__button {
    border: 0;
    background: transparent;
    padding: 4px 6px;
  }
  
  .react-daterange-picker__button:enabled {
    cursor: pointer;
  }
  
  .react-daterange-picker__button:enabled:hover .react-daterange-picker__button__icon,
  .react-daterange-picker__button:enabled:focus .react-daterange-picker__button__icon {
    stroke: var(--gray-10);
  }
  
  .react-daterange-picker__button:disabled .react-daterange-picker__button__icon {
    stroke: #6d6d6d;
  }
  
  .react-daterange-picker__button svg {
    display: inherit;
    stroke-width: 1;
    stroke: var(--gray-12);
  }
  
  .react-daterange-picker__calendar {
    width: 350px;
    max-width: 100vw;
    z-index: 1;
  }

  .react-daterange-picker__calendar-button__icon {
    stroke-width: 1;
    stroke: var(--gray-12);
  }
  
  .react-daterange-picker__calendar--closed {
    display: none;
  }
  
  .react-daterange-picker__calendar .react-calendar {
    border: thin solid var(--gray-4);
  }

.react-calendar {
    background-color: var(--gray-2) !important;
}

.react-calendar__tile {
    background-color: var(--gray-2);
}

.react-calendar__tile--active {
    background-color: var(--gray-4) !important;
}

.react-calendar__tile--angeBothEnds {
    background-color: var(--gray-4) !important;
}

.react-calendar__tile--now {
    background-color: var(--gray-3) !important;
}

.react-calendar__tile--active:enabled {
    background-color: var(--gray-6) !important;
}

.react-calendar__tile:hover {
    background-color: var(--gray-6) !important;
}

.react-calendar__tile:active {
    background: var(--gray-4)
}

.react-calendar__tile--hover {
    background: var(--gray-4) !important;
}

.react-calendar__navigation__label:hover {
    background-color: var(--gray-6) !important;
}

.react-calendar__navigation__label:disabled,
.react-calendar__navigation__label:focus {
    background-color: var(--gray-2) !important;
}

.react-calendar__navigation__arrow:hover {
    background-color: var(--gray-6) !important;
}

.react-calendar__navigation__arrow:focus,
.react-calendar__navigation__arrow:disabled {
    background-color: var(--gray-2) !important;
}

.react-calendar__month-view__days__day--weekend {
    color: var(--gray-10) !important;
}

/* .react-calendar__month-view__days__day — weekend: Sets the color of weekend days.
.react-calendar__tile — now: Adjusts the background color of the current date tile.
.react-calendar__month-view__days__day — neighboringMonth: Hides neighboring month days.
.calender-body: Sets the background color for the calendar body. */
