.select {
    display: inline-flex;
    height: 2.5rem;
    align-items: center;
    justify-content: space-between;
    gap: 0.25rem;
    border-radius: 0.25rem;
    background-color: var(--accent-a3);
    color: var(--accent-12);
    width: 300px;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  
  .select:hover {
    background-color: var(--gray-3);
  }
  
  @media (min-width: 640px) {
    .select {
      height: 2.25rem;
      font-size: 15px;
    }
  }
  
  .select-icon {
    translate: 4px 0;
  }
  .select-root {
    width: 300px;
  }
  
  .popover {
    z-index: 50;
    max-height: min(var(--radix-select-content-available-height), 336px);
    border-radius: 0.25rem;
    background-color: var(--accent-3);
    color: var(--accent-12);
    width: 300px;
  }
  
  .combobox-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    padding: 0.25rem;
    padding-bottom: 0px;
    width: 300px;
    color: var(--accent-12);
  }
  
  .combobox {
    height: 2.5rem;
    appearance: none;
    border-radius: 0.25rem;
    background-color: var(--gray-2);
    padding-right: 0.5rem;
    padding-left: 1.75rem;
    color: var(--accent-12);
    outline: 2px solid transparent;
    outline-offset: 2px;
    width: 300px;
  }
  
  .combobox::placeholder {
    color: var(--gray-9);
  }
  
  @media (min-width: 640px) {
    .combobox {
      height: 2.25rem;
      font-size: 15px;
    }
  }
  
  .combobox-icon {
    pointer-events: none;
    position: absolute;
    left: 0.625rem;
    color: var(--accent-12);
  }
  
  .listbox {
    overflow-y: auto;
    padding: 0.25rem;
  }
  
  .item {
    position: relative;
    display: flex;
    height: 3.5rem;
    cursor: default;
    scroll-margin-top: 0.25rem;
    scroll-margin-bottom: 0.25rem;
    align-items: center;
    border-radius: 0.25rem;
    padding-left: 1.75rem;
    padding-right: 1.75rem;
    color: var(--accent-12);
    outline: 2px solid transparent;
    outline-offset: 2px;
  }

  
  .item[data-active-item] {
    background-color: var(--gray-2);
    color: var(--accent-12);
  }
  
  @media (min-width: 640px) {
    .item {
      font-size: 15px;
    }
  }
  
  .item-indicator {
    position: absolute;
    left: 0.375rem;
  }