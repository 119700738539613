@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}

html {
  background-color: var(--color-background);
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  padding: 0;
  font-family: var(--font-poppins);
}

@media print {
  @page {
    background-color: var(--color-background);
    border: 10px solid red;
  }
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

body {
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader {
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top: 2px solid white;
  width: 16px;
  height: 16px;
  animation: spin 1s linear infinite;
}

/*================================*/
.grrid {
  width: 100%;
  display: grid;
  height: calc(100vh - 200px);
  grid-template-areas: "a b " "c d ";
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-gap: 20px;
  position: relative;
}

.grrid > .item:nth-child(1) {
  grid-area: a;
  top: 0;
  left: 0;
}

.grrid > .item:nth-child(2) {
  grid-area: b;
  top: 0;
  right: 0;
}

.grrid > .item:nth-child(3) {
  grid-area: c;
  bottom: 0;
  left: 0;
}

.grrid > .item:nth-child(4) {
  grid-area: d;
  bottom: 0;
  right: 0;
}

/* Extra care for the middle elements
 * So they can transition from the middle  
*/

.grrid > .item {
  position: absolute;
  width: 100%;
  height: 100%;
  transition:
    z-index 0.2s step-end,
    width 0.2s linear,
    height 0.2s linear;
}

/* width: calc(300% + 40px); 
 * 200% beacause we have 3 columns each column is 100%
 * becasue the grrid item is relative to it's grid area not the grid container
 * 2 columns is 100% * 2 = 200%
 * 40px is the grrid gap, You can css variables to make it accessible for the grid items.
 * Same applies to the height.
*/

.grrid > .item-active {
  height: calc(200% + 20px);
  height: calc(100vh - 200px);
  width: calc(200% + 20px);

  transition:
    z-index 0.2s step-start,
    width 0.2s linear,
    height 0.2s linear;
  z-index: 5;
}

.donut-inner {
  position: absolute;
  margin-top: -100px;
  margin-bottom: 100px;
}
.donut-inner h5 {
  margin-bottom: 5px;
  margin-top: 0;
}
.donut-inner span {
  font-size: 12px;
}

@media print {
  body {
    margin: 0;
    color: #0ff;
    background-color: #fff;
  }
}

.App {
  text-align: center;
}

.ReactCrop__image {
  max-width: 100%;
  max-height: 200px;
  object-fit: contain;
}

.loader {
  border-top-color: #151515;
  width: 70px;
  height: 70px;
  animation: spin 1s linear infinite;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
.reactions-statuses {
  padding: 12px;
  text-align: center;
  font-size: 12px;
  color: #9b9b9b;
  border-bottom: 1px solid #4b5563;
}
.sli-refresh::before {
  content: "\e098";
}
.sli::before {
  display: inline-block;
  font: normal normal normal 24px/1 simple-line-icons;
  font-size: 24px;
  line-height: 1;
  font-size: inherit;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  line-height: inherit;
  text-rendering: auto;
}

div[data-supertokens="headerTitle"]::before {
  content: url("/logo.png");
  /* Additional styling to position and size the image as needed */
  display: inline-block;
  width: 100px; /* hideScrollbar size */
  height: 100px; /* hideScrollbar size */
  margin-right: 10px; /* hideScrollbar spacing */
}

/* nextjs-portal { */
/*   display: none; */
/* } */

#supertokens-root {
  background-color: #151515;
}

@layer base {
  html[data-theme="black"] body * {
    --color-main-background: #111113;
    --color-main-background-split: 29, 29, 29;
    --color-main-text: white;
    --color-main-text-secondary: #8f8f8f;
    --color-main-text-split: 255, 255, 255;
    --color-main-secondary: #363535;
    --color-main-secondary-split: 54, 53, 53;
    --color-main-border: white;
    --color-nav-top: #2d2d2d;
    --color-nav-bottom: #1e1e1e;
    --color-button-primary: black;
  }

  html[data-theme="research"] main.content {
    --color-main-background: #003b3f;
    --color-main-background-split: 50, 168, 82;
    --color-main-text: white;
    --color-main-text-secondary: #afafaf;
    --color-main-text-split: 255, 255, 255;
    --color-main-secondary: #005a5e;
    --color-main-secondary-split: 0, 91, 94;
    --color-main-border: white;
    --color-nav-top: #003b3f;
    --color-nav-bottom: #005a5e;
    --color-button-primary: #0f1110;
  }

  .customm {
    @apply font-poppins;
  }

  html[data-theme="white"] main.content {
    
    --color-main-background: #f4f4f4;
    --color-main-background-split: 244, 244, 244;
    --color-main-text: black;
    --color-main-text-secondary: #3f3f3f;
    --color-main-text-split: 0, 0, 0;
    --color-main-secondary: #d4d4d4;
    --color-main-secondary-split: 212, 212, 212;
    --color-main-border: black;
    --color-nav-top: #f4f4f4;
    --color-nav-bottom: #b4b4b4;
    --color-button-primary: black;
  }

  html[data-theme="grey"] main.content {
    @apply !font-montserrat;
    --color-main-background: #737373;
    --color-main-background-split: 115, 115, 115;
    --color-main-text: white;
    --color-main-text-secondary: #2f2f2f;
    --color-main-text-split: 255, 255, 255;
    --color-main-secondary: #828282;
    --color-main-secondary-split: 52, 52, 52;
    --color-main-border: gray;
    --color-nav-top: #737373;
    --color-nav-bottom: #828282;
    --color-button-primary: black;
  }

  html[data-theme="red"] main.content {
    @apply !font-montserrat;
    --color-main-background: #fd5959;
    --color-main-background-split: 253, 89, 89;
    --color-main-text: black;
    --color-main-text-secondary: #2f2f2f;
    --color-main-text-split: 0, 0, 0;
    --color-main-secondary: #ed4545;
    --color-main-secondary-split: 237, 69, 69;
    --color-main-border: gray;
    --color-nav-top: #fd5959;
    --color-nav-bottom: #ed4545;
    --color-button-primary: black;
  }

  html[data-theme="orange"] main.content {
    @apply !font-inter;
    --color-main-background: #ffc259;
    --color-main-background-split: 255, 194, 89;
    --color-main-text: black;
    --color-main-text-secondary: #2f2f2f;
    --color-main-text-split: 0, 0, 0;
    --color-main-secondary: #ffbb45;
    --color-main-secondary-split: 255, 187, 69;
    --color-main-border: gray;
    --color-nav-bottom: #ffc259;
    --color-nav-top: #f5b038;
    --color-button-primary: black;
  }

  html[data-theme="yellow"] main.content {
    @apply !font-montserrat;
    --color-main-background: #fff459;
    --color-main-background-split: 255, 228, 89;
    --color-main-secondary: #f2e52e;
    --color-main-text: black;
    --color-main-text-secondary: #666;
    --color-main-text-split: 0, 0, 0;
    --color-main-secondary: #f2e52e;
    --color-main-secondary-split: 242, 229, 46;
    --color-main-border: black;
    --color-nav-top: #fff459;
    --color-nav-bottom: #f2e52e;
    --color-button-primary: black;
  }

  html[data-theme="green"] main.content {
    
    --color-main-background: #cbff88;
    --color-main-background-split: 203, 255, 136;
    --color-main-secondary: #a8ff78;
    --color-main-text: black;
    --color-main-text-secondary: #666;
    --color-main-text-split: 0, 0, 0;
    --color-main-secondary-split: 168, 255, 120;
    --color-main-border: black;
    --color-nav-top: #cbff88;
    --color-nav-bottom: #a8ff78;
    --color-main-text-split: 0, 0, 0;
    --color-button-primary: black;
  }

  html[data-theme="blue"] main.content {
    --color-main-background: #a0c8e6;
    --color-main-background-split: 160, 200, 230;
    --color-main-secondary: #6e9cc8;
    --color-main-secondary-split: 110, 156, 200;
    --color-main-text: black;
    --color-main-text-secondary: #666;
    --color-main-border: black;
    --color-nav-bottom: #a0c8e6;
    --color-nav-top: #6e9cc8;
    --color-button-primary: black;
  }

  html[data-theme="pink"] main.content {
    
    --color-main-background: #ff9dd9;
    --color-main-background-split: 255, 157, 217;
    --color-main-text: black;
    --color-main-text-secondary: #666;
    --color-main-text-split: 0, 0, 0;
    --color-main-secondary: #e87bbe;
    --color-main-secondary-split: 232, 123, 190;
    --color-main-border: black;
    --color-nav-top: #ff9dd9;
    --color-nav-bottom: #e87bbe;
    --color-button-primary: black;
  }

  html[data-theme="purple"] main.content {
    @apply !font-inter;
    --color-main-background: #bf7cd1;
    --color-main-secondary: #ad58c4;
    --color-main-text: white;
    --color-main-text-split: 255, 255, 255;
    --color-main-text-secondary: #bfbfbf;
    --color-main-secondary-split: 173, 88, 196;
    --color-main-border: black;
    --color-nav-top: #bf7cd1;
    --color-nav-bottom: #ad58c4;
    --color-button-primary: black;
  }
}

.embla {
  overflow: hidden;
}
.embla__container {
  display: flex;
}
.embla__slide {
  flex: 0 0 100%;
  min-width: 0;
}

.swiper {
  width: 100%;
  height: 100%;
}

.hideScrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hideScrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.out-box {
  width: 100%;
  overflow: hidden;
}
.shape-box {
  border-bottom-left-radius: 100% 250px;
  border-bottom-right-radius: 100% 250px;
  width: calc(100% + 100px);
  margin-left: -50px;
  overflow: hidden;
}
.image-box {
  height: 320px;
  width: 100%;
  /* background: transparent */
  /*   url("https://fosgla.by3302.livefilestore.com/y4mLTJEvqsyroU2934hwf3LE0OfcVkrSS-5cV4bW-UPg3zw8PXMCahOSxSpsdSyBD-sPYBKY-nkpuvTNwajReURD-V3BtbeyQGVzShXR-n_YD4QulL9tLjImDuklaBOYpCoAQGzQn1BlrZK4Z3B66Kx2yICVfOS3_pcgMw8sSPICKlNbbMqyjJSC87-8m3FwXDrmy2tGuwmZQF6xdDUPNZGTA?width=640&height=425&cropmode=none") */
  /*   no-repeat; */
  background-size: cover;
  background-position: center center;
}

.status-dot-inactive {
  display: flex;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 5px;
  background-color: #dfa0a0;
}

.status-dot-active {
  display: flex;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 5px;
  background-color: #CCFF89;
}

.status-dot-draft {
  display: flex;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 5px;
  background-color: #89E3FF;
}

.box {
  border-radius: 12px;
  background-color: var(--gray-2);
}

.box-dark {
  border-radius: 12px;
  background-color: var(--gray-1);
}

.box-mobile {
  border-radius: 0px;
  background-color: var(--gray-2);
  }

.small-thumbnail {
  border-radius: 4px;
  height: 60px;
  width: 35px;
  object-fit: cover;
}

.icon {
  stroke-width: 1;
  color: var(--gray-12);
}
.icon-accent {
  stroke-width: 1;
  color: var(--accent-a);
}
.icon-dark {
  stroke-width: 1;
  color: var(--gray-11);
}

.menu-selected {
  background-color: var(--color-background);
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
}

.overlay-wrap {
  position: relative;
  transition: transform 150ms ease-in-out;
}

.overlay-img {
  display: block;
  min-width: 96px;
  min-height: 112px;  
  color: var(--gray-3)
}

.overlay-img-mobile {
  display: block; 
  color: var(--gray-3)
}

.overlay-wrap:hover .overlay-img {
  color: var(--accent-4);
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
}

.active {
  color: var(--color-background);
}

.layout {
  background-color: var(--gray-3);
  border-radius: 20px;
}
  .mobile {
    border-radius: 0px;
  }

.bottom {
  position: fixed;
  bottom: 0px;
  width: 100%;
}

.creator-icon {
  width: 32px;
  height: 32px;
  background: grey;
  border-radius: 100%;
}

.progress {
  background-color: var(--gray-8);
}

.radix-themes {
  --default-font-family: var(--font-poppins);
  font-family: var(--font-poppins);
}

.content {
  font-family: var(--font-poppins)
}

.link:hover {
  text-decoration: underline;
}

.text-accent {
  color: var(--accent-a)
}

.profile-background {
  background-color: white;
  border-radius: 100%;
}

