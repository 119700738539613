.horizental-line {
    width: 80%;
    height: 2px;
    background-color: var(--gray-6);
}

.dot {
    display: flex;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: var(--gray-11);
  }

.circle {
    border-radius: 50%;
    height: 20px;
    width: 20px;
    min-height: 20px;
    min-width: 20px;
    border: 2px solid var(--gray-8);
  }

.current {
    border: 2px solid var(--gray-11);
}

.text-dark {
    color: var(--gray-8);
}

.preview {
    background-color: var(--gray-2);
}

.option {
    border: 1px solid var(--gray-8);
    border-radius: 12px;
    height: fit-content;
    padding-left: 1.75rem;
    padding-right: 1.75rem;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    align-items: center;
}

.placeholder {
    height: 25px;
    background-color: var(--gray-9);
    border-radius: 100px;
}

.platforms {
    height: 15vh;
    min-width: 10vw;
    border-radius: 10px;
    border: 1px solid var(--gray-8);
    flex-grow: 1;
}

.selected {
    background-color: var(--gray-3);
    border: 1px solid var(--gray-11);
}

.creator {
    border-top: 1px solid var(--gray-5);
    height: 50px;
    align-items: center;
    padding-left: 1rem;
    padding-right: 1rem;
}

.creator:hover {
    background-color: var(--gray-5);
}

.tall {
    height: 70px;
}

.post {
    width: 150px;
    height: 280px;
    background-color: var(--gray-9);
    border-radius: 18px;
}

.post-preview {
    width: 165px;
    height: 310px;
    background-color: var(--gray-9);
    border-radius: 25px;
}

.post-preview-youtube {
    height: 220px;
    width: 440px;
    background-color: var(--gray-9);
    border-radius: 18px;
}
.post-preview-youtube-mobile {
    height: 140px;
    width: 290px;
    background-color: var(--gray-9);
    border-radius: 18px;
}

.phone-wrap {
    position: relative;
    display: inline;
    transition: transform 150ms ease-in-out;
  }

.phone {
    position: absolute;
    top: 0;
    left: 0;
}

.playlist-box {
    height: 100px;
    width: 345px;
    background-color: var(--gray-3);
    box-shadow: 10px 10px 10px black;
}

.playlist-thumbnail {
    width: 52px;
    height: 52px;
    object-fit: cover;
    border-radius: 4px;
}

.playlist-thumbnail-large {
    width: 70px;
    height: 70px;
    object-fit: cover;
    border-radius: 4px;
}

.playlist {
    border-top: 1px solid var(--gray-5);
    height: 70px;
    align-items: center;
    padding-left: 1rem;
    padding-right: 1rem;
}

.playlist:hover {
    background-color: var(--gray-5);
}

.gradient {
    position: absolute;
    top: 20%;
    left: 5%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 55) 75%, rgba(0, 0, 0, 70) 100%);
    width: 90%;
    height: 52%;
}
  
.header {
    position: absolute;
    top: 22%;
    left: 14%;
    background-color: white;
    color: black;
    font-size: 7px;
}

.header-rap {
    position: relative;
    top: 0px;
    left: 0px;
    background-color: white;
    color: black;
    font-size: 7px;
}

.headline {
    position: absolute;
    width: 100%;
    bottom: 36%;
    left: 0px;
    color: white;
}

.wavelogo {
    position: absolute;
    bottom: 32%;
    left: 0px;
}

.postfooter {
    height: 2px;
    width: 47%;
    background-color: white;
}

.caption {
    position: absolute;
    bottom: 18%;
    left: 9%;
    margin-right: 9%;
    color: white;
    font-size: 7px;
}

.username {
    position: absolute;
    top: 13.2%;
    left: 19%;
    width: 100px;
    font-size: 7px;
    background-color: black;
}

.record-image {
    position: absolute;
    top: 50px;
    left: 57px;
    width: 220px;
    height: 220px;
}

.record-image-mobile {
    position: absolute;
    top: 37px;
    left: 42px;
    width: 160px;
    height: 160px;
}

.xembed {
    :global(.tweet-container-module__CmFQMq__root) {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
        width: 100px !important;
        height: 280px !important;
    }
}

.x-1 {
    position: absolute;
    top: 46.5%;
    left: 14.5%;
    width: 100px;
    font-size: 6px;
    font-weight: bold;
    background-color: black;
}

.x-2 {
    position: absolute;
    top: 55%;
    left: 14.5%;
    width: 100px;
    font-size: 6px;
    font-weight: bold;
    background-color: black;
}

.x-3 {
    position: absolute;
    top: 62%;
    left: 14.5%;
    width: 100px;
    font-size: 6px;
    font-weight: bold;
    background-color: black;
}

.platforms-proposal {
    height: 190px;
    min-width: 275px;
    border-radius: 10px;
    border: 1px solid var(--gray-8);
    flex-grow: 1;
}

.platforms-proposal:hover {
    background-color: var(--gray-3);
}

.platforms-proposal-mobile {
    height: 100%;
    width: 100%;
    padding: 20px;
    border-radius: 10px;
    border: 1px solid var(--gray-8);
    justify-content: start;
}

 